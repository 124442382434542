import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import Cta from "../styleguide/Cta";
import SimpleLogo from '../../assets/simple-logo.png'
import styles from '../../styles/info-site/StageContent.module.css'

const StageContent = ({
  title,
  content,
  ctaLink,
  ctaText,
  list,
  listContent,
  wideText,
  className,
}) => {
  return (
    <div className={`${styles.StageContent} ${wideText ? styles.StageContent__wide: ''} ${className ? className : ''}`}>
      <div className={styles.StageContentWrapper}>
        <h3><FormattedMessage id={title} /></h3>
        {
          listContent && (
            <>
              {
                listContent.title && <p><FormattedHTMLMessage id={listContent.title} /></p>
              }
              <ul className={styles.StageContentList}>
                {listContent.items.map((item, idx) => (
                  <li key={`stage-list-content--${idx}`} className={styles.StageContentListContentItem}>
                    <p><FormattedHTMLMessage id={item} /></p>
                  </li>
                ))}
              </ul>
            </>
          )
        }
        {
          content && <p><FormattedHTMLMessage id={content} /></p>
        }
        {
          list && list.length > 0 && (
            <ul className={styles.StageContentList}>
              {list.map((item) => (
                <li key={item} className={styles.StageContentListItem}>
                  <img src={SimpleLogo} alt='' />
                  <p><FormattedHTMLMessage id={item} /></p>
                </li>
              ))}
            </ul>
          )
        }
        <Cta className={styles.StageContentCta} link={ctaLink} text={ctaText} />
      </div>
    </div>
  )
}

export default StageContent