import React from "react";
import styles from "../../styles/info-site/WhyTyviso.module.css";
import { FormattedMessage } from "react-intl";
import TyvisoLogo from "../../assets/logo.png";
import GoogleLogo from "../../assets/google-logo.png";
import FacebookLogo from "../../assets/facebook-logo.png";
import Yes from "../../assets/yes.png";
import No from "../../assets/no.png";
import { comparisonTableData } from "../../data/comparisonTableData";

const WhyTyviso = ({ title, subtitle }) => {
    const renderComparisonTableValue = (value) => {
        if (typeof value === "boolean") {
            return value ? <img src={Yes} alt="Yes" /> : <img src={No} alt="No" />;
        } else {
            return value;
        }
    };

    const renderComparisonTable = () => {
        return (
            <React.Fragment>
                {comparisonTableData.map((item, key) => {
                    return (
                        <tr key={key}>
                            <td><FormattedMessage id={item.label} /></td>
                            <td>{renderComparisonTableValue(item.tyviso)}</td>
                            <td>{renderComparisonTableValue(item.google)}</td>
                            <td>{renderComparisonTableValue(item.facebook)}</td>
                        </tr>
                    );
                })}
            </React.Fragment>
        );
    };

    return (
        <div className={styles.WhyTyviso}>
            {
              title && <h2 className={styles.WhyTyvisoTitle}><FormattedMessage id={title} /></h2>
            }
            {
              subtitle && <p className={styles.WhyTyvisoDescription}><FormattedMessage id={subtitle} /></p>
            }
            <div className={styles.WhyTyvisoTablePlaceholder}>
                <table className={styles.WhyTyvisoTable}>
                    <thead>
                        <tr>
                            <th></th>
                            <th><img src={TyvisoLogo} alt="Tyviso" /></th>
                            <th><img src={GoogleLogo} alt="Google" /></th>
                            <th><img src={FacebookLogo} alt="Facebook" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderComparisonTable()}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default WhyTyviso;