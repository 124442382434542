export const comparisonTableData = [
    {
        label: "averageRoi",
        tyviso: "666%",
        google: "287%",
        facebook: "395%"
    },
    {
        label: "averageEcommerceCvr",
        tyviso: "8%",
        google: "2%",
        facebook: "3%"
    },
    {
        label: "averageAdCtr",
        tyviso: "12%",
        google: "2%",
        facebook: "1%"
    },
    {
        label: "ecomPlatformSupport",
        tyviso: true,
        google: true,
        facebook: true
    },
    {
        label: "machineLearning",
        tyviso: true,
        google: true,
        facebook: true
    },
    {
        label: "tracking",
        tyviso: true,
        google: true,
        facebook: true
    },
    {
        label: "allDeviceSupport",
        tyviso: true,
        google: true,
        facebook: true
    },
    {
        label: "paymentOnPerformance",
        tyviso: true,
        google: false,
        facebook: false
    },
    {
        label: "brandSafe",
        tyviso: true,
        google: false,
        facebook: false
    },
    {
        label: "freeLoyaltyTool",
        tyviso: true,
        google: false,
        facebook: false
    },
    {
        label: "noOptimisations",
        tyviso: true,
        google: false,
        facebook: false
    }
];