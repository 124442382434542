import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import SimpleLogo from '../../assets/simple-logo.png'

import styles from "../../styles/info-site/CaseStudyOverview.module.css";
import Cta from '../styleguide/Cta';

function CaseStudyOverview({
  title,
  subtitle,
  categoryContent,
  overviewTitle,
  overviewRows,
  ctaLink,
  ctaText
}) {
  return (
    <div className={styles.CaseStudyOverview}>
      <h2 className={styles.CaseStudyOverviewTitle}><FormattedMessage id={title} /></h2>
      <p className={styles.CaseStudyOverviewSubtitle}><FormattedMessage id={subtitle} /></p>
      <div className={styles.CaseStudyOverviewContent}>
        <div className={styles.CaseStudyOverviewSide}>
          <div className={styles.CaseStudyOverviewSideWrapper}>
            <h2><FormattedMessage id={categoryContent} /></h2>
          </div>
        </div>
        <div className={styles.CaseStudyOverviewSide}>
          <div className={styles.CaseStudyOverviewSideWrapper}>
            <h3><FormattedMessage id={overviewTitle} /></h3>
            {
              overviewRows && overviewRows.length > 0 && (
                <ul className={styles.CaseStudyOverviewList}>
                  {
                    overviewRows.map((item, idx) => (
                      <li key={`case-study-overview--${idx}`} className={styles.CaseStudyOverviewListItem}>
                        <img src={SimpleLogo} alt='' />
                        <p><FormattedHTMLMessage id={item} /></p>
                      </li>
                    ))
                  }
                </ul>
              )
            }
            {
              ctaText && <Cta className={styles.CaseStudyOverviewCta} link={ctaLink} text={ctaText} />
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default CaseStudyOverview;