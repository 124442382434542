import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/free-solid-svg-icons";

import styles from "../../styles/info-site/HomepageHowItWorks.module.css";

const HomepageHowItWorks = ({
  title,
  steps,
  highlighted
}) => {
    return (
        <>
          <h2 className={`${styles.HomepageHowItWorksTitle} ${highlighted ? styles.HomepageHowItWorksTitle_highlighted : ''}`}><FormattedHTMLMessage id={title} /></h2>
          <div className={styles.HomepageHowItWorks}>
              {
                steps && steps.length > 0 && (
                  <div className={`${styles.HomepageHowItWorksPlaceholder} ${steps.length < 4 ? styles.HomepageHowItWorksPlaceholder_center : ''}`}>
                    {
                      steps.map(({ id, icon }) => (
                        <p key={id} className={styles.HomepageHowItworksItem}>
                          <FormattedHTMLMessage id={id} />
                          {
                            icon && (
                              <span className={`${styles.HomepageHowItworksItemIcon} ${styles[`HomepageHowItworksItemIcon__${icon}`]}`}>
                                <FontAwesomeIcon icon={faRightLong} />
                              </span>
                            )
                          }
                        </p>
                      ))
                    }
                  </div>
                )
              }
          </div>
        </>
    );
};

export default HomepageHowItWorks;