import React from "react";
import Stage from "../components/info-site/Stage";
import Layout from "../layouts/en";
import Seo from "../components/info-site/Seo";
import HomepageInfoSection from "../components/info-site/HomepageInfoSection";
import WhyTyviso from "../components/info-site/WhyTyviso";
import BiggestChallanges from "../components/info-site/BiggestChallanges";
import GetStarted from "../components/info-site/GetStarted";
import CaseStudyOverview from "../components/info-site/CaseStudyOverview";
import HomepageHowItWorks from "../components/info-site/HomepageHowItWorks";
import VideoPlayer from "../components/styleguide/VideoPlayer"
import CompaniesTestimonials from "../components/info-site/CompaniesTestimonials";
import StageContent from "../components/info-site/StageContent";

import { homepageTestimonials } from "../data/companiesTestimonials";

const TyvisoRecommends = props => {
    return (
			<Layout location={props.location}>
				<Stage title="recommendsTitle" icon withoutMedia>
					<Seo
						titleId="recommendsTitle"
						descriptionId="recommendsDescription"
					/>
					<StageContent
						title="recommendsSideTitle"
						content="recommendsSideDescription"
						ctaLink="https://rtbmedia.hubspotpagebuilder.com/book-a-demo"
						ctaText="homepageStagePrimaryCta"
					/>
				</Stage>

				<CompaniesTestimonials
					title="allStars"
					companies={homepageTestimonials}
				/>

				<VideoPlayer
					youtube={{
						src: 'https://www.youtube.com/embed/9dy5LnxVbKs',
						title: 'Tyviso Recommends Setup Guide Video',
					}}
				/>

				<HomepageHowItWorks
					title="recommendsHowItWorksTitle"
					steps={[
						{
							id: 'recommendsHowItWorksOne',
							icon: 'blue',
						},
						{
							id: 'recommendsHowItWorksTwo',
							icon: 'turquoise',
						},
						{
							id: 'recommendsHowItWorksThree',
							icon: 'green',
						},
						{
							id: 'recommendsHowItWorksFour',
						},
					]}
				/>

				<HomepageInfoSection />

				<CaseStudyOverview
					title="caseStudyOverviewTitle"
					subtitle="caseStudyOverviewSubtitle"
					categoryContent="caseStudyOverviewVitaminsContent"
					overviewTitle="caseStudyOverview"
					overviewRows={[
						'caseStudyOverviewOne',
						'caseStudyOverviewTwo',
						'caseStudyOverviewThree',
					]}
					ctaLink="/success-stories/supplement-case"
					ctaText="caseStudyOverviewCtaText"
				/>
				<WhyTyviso title="dontTakeOurWord" />
				<BiggestChallanges
					title="goals"
					centered
					cards={[
						{
							id: 'cpa',
							link: '/your-goals/acquire-new-customers',
							text: 'biggestChallangesCardOneTitle',
						},
						{
							id: 'ltv',
							link: '/your-goals/raise-customer-lifetime',
							text: 'biggestChallangesCardTwoTitle',
						},
						{
							id: 'revshare',
							link: '/your-goals/monetise-your-ecommerce',
							text: 'biggestChallangesCardThreeTitle',
						},
						{
							id: 'cro',
							link: '/your-goals/uplift-basket-completion',
							text: 'biggestChallangesCardFourTitle',
						},
					]}
				/>
				<GetStarted />
			</Layout>
		);
};

export default TyvisoRecommends;