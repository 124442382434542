import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import SimpleLogo from "../../assets/simple-logo-white.png"

import styles from '../../styles/info-site/BiggestChallanges.module.css'

const BiggestChallanges = ({ cards, title, subTitle, centered }) => {
  return (
    <div className={`${styles.BiggestChallanges} ${centered ? styles.BiggestChallanges__center : ''}`}>
      <h2><FormattedMessage id={title} /></h2>
      {
        subTitle && <p><FormattedMessage id={subTitle} /></p>
      }
      <ul className={styles.BiggestChallangesList}>
        {
          cards.map(({ id, text, link}) => (
            <li className={styles.BiggestChallangesListItem} key={id}>
              <a className={styles.BiggestChallangesLink} href={link}>
                <div className={styles.BiggestChallangesLinkContainer}>
                  <h3><FormattedHTMLMessage id={text} /></h3>
                </div>
                <div className={styles.BiggestChallangesLinkFooter}>
                  <span><FontAwesomeIcon icon={faArrowRight} /></span>
                  <img src={SimpleLogo} alt="" />
                </div>
              </a>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default BiggestChallanges