import React from "react";
import styles from "../../styles/info-site/HomepageInfoSection.module.css";
import WhatWeDoImage from "../../assets/what-we-do.jpg";
import TextInfo from "../styleguide/TextInfo";
import Cta from "../styleguide/Cta";
import { FormattedMessage } from "react-intl";

const HomepageInfoSection = () => {
    return (
        <div className={styles.HomepageInfoSection}>
            <h2 className={styles.HomepageInfoSectionTitle}><FormattedMessage id="homepageInfoTitle" /></h2>
            <div className={styles.HomepageInfoSectionPlaceholder}>
                <div className={styles.HomepageInfoSectionImage}>
                    <img src={WhatWeDoImage} alt="Tyviso offers" />
                </div>
                <div className={styles.HomepageInfoSectionText}>
                    <TextInfo className={styles.HomepageInfoSectionInfo} title="homepageInfoOneTitle" description="homepageInfoOneDescription" />
                    <TextInfo className={styles.HomepageInfoSectionInfo} title="homepageInfoTwoTitle" description="homepageInfoTwoDescription" />
                    <TextInfo className={styles.HomepageInfoSectionInfo} title="homepageInfoThreeTitle" description="homepageInfoThreeDescription" />
                    <TextInfo className={styles.HomepageInfoSectionInfo} title="homepageInfoFourTitle" description="homepageInfoFourDescription" />
                    <TextInfo className={styles.HomepageInfoSectionInfo} title="homepageInfoFiveTitle" description="homepageInfoFiveDescription" />
                    <Cta className={styles.HomepageInfoSectionCta} link="/learn-more" text="learnMore" />
                </div>
            </div>
        </div>
    );
};

export default HomepageInfoSection;